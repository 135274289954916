// i18n.js
import i18n from "i18next";
import StudentReceipt from "pages/AddStudent/StudentReceipt";
import { initReactI18next } from "react-i18next";

// Import translations
const resources = {
  en: {
    translation: {
      // Your English translations go here
      welcome: "Welcome",
      staff: "Staff",
      dashboard: "Dashboard",
      institute: "Institute",
      instituteName: "Institute Name",
      batch: "Batch",
      student: "Student",
      submitFee: "Submit Fee",
      fees: "Fees",
      salary: "Salary",
      expenses: "Expenses",
      instituteKit: "Institute Kit",
      logout: "Logout",
      studentSummary: "Student Summary",
      staffSummary: "Staff Summary",
      newAdmission: "New Admission",
      newStaff: "New Staff",
      paySalary: "Pay Salary",
      newBatch: "New Batch",
      newExpenses: "New Expenses",
      newSInstituteKit: "New Institute kit",
      sellInstituteKit: "Institute Kit Sales",
      kitSales: "Kit Sales",
      fromDate: "From date",
      toDate: "To date",
      feeCollection: "Fee Collection",
      pendingFees: "Pending Fees",
      discountFees: "Discoumnt Fees",
      paidSalary: "Paid Saalary",
      salaryDue: "Salary Due",
      studentKitSales: "Student Kit Sales",
      feeDetails: "Fee details",
      instituteMonthlySummary: "Institute Monthly Summary",
      sales: "Sales",
      salaryDetails: "Salary details",
      batchSummary: "Batch Summary",
      male: "Male",
      female: "Female",
      studentsOfEachBatch: "Students of each batch",
      totalStaffs: "Total Staffs",
      totalStudents: "Total Students",
      instituteRegistrationNo: "Institute registration no.",
      instituteMobile: "Institute mobile",
      instituteEmail: "Institute email",
      city: "City",
      pinCode: "Pin Code ",
      state: "State",
      country: "Country",
      address: "Address",
      instituteLogo: "Institute logo",
      authorisedSignature: "Authorised signature",
      note: "Note*: Institute details, Logo and Signature will be used in Student ID card, Fees receipts, Salary receipts etc.",
      name: "Name",
      batchTeacher: "Batch teacher",
      feeType: "Fee type",
      defaultFee: "Default fee",
      startDate: "Start date",
      endDate: "End date",
      mobile: "Mobile no.",
      email: "Email",
      adharNumber: "Adhar Number",
      qualification: "Qualification",
      joiningDate: "Joining date",
      salaryAmount: "Salary Amount",
      role: "Role",
      admin: "Admin",
      teacher: "Teacher",
      principal: "Principal",
      other: "Other",
      salaryType: "Salary type",
      monthly: "Monthly",
      courseWise: "Course Wise",
      oneTime: "One Time",
      gender: "Gender",
      attachPhoto: "Attach photo",
      addStaff: "Add Staff",
      updateStaff: "Update Staff",
      updateInstituteDetails: "Update institute details",
      addYourInstitute: "Add your institute",
      updateInstitute: "Update Institute",
      profile: "Profile",
      staffNo: "Staff No.",
      action: "Action",
      to: "To",
      from: "From",
      updateBatchDetails: "Update batch details",
      addBatch: "Add Batch",
      batchNumber: "Batch number",
      searchStaffs: "Search staffs....",
      searchBatch: "Search batch....",
      mother: "Mother",
      father: "Father",
      rollNo: "Roll no.",
      dob: "DOB",
      mobileNo: "Mobile",
      newStudent: "New Student",
      studentList: "Student list",
      searchStudents: "Serch studdent.....",
      selectBatch: "Select Batch",
      reset: "Reset",
      updateStudentDetails: "Update student details",
      addStudent: "Add Student",
      caste: "Caste",
      admissionDate: "Admission date",
      paid: "Paid",
      discount: "Discount",
      remaining: "Remaining",
      paymentDate: "Payment date",
      receipt: "Receipt",
      submitFees: "Submit Fees",
      receivedFeesList: "Received Fees List",
      searchStudentByNameRollNo: "Search student by Name, Roll No.",
      searchStaffByNameStaffId: "Search Staff by Name, Staff Id",
      addFees: "Add Fees",
      updateFeesDetails: "Update fees details",
      discountAmount: "Discount amount",
      payAmount: "Pay amount",
      remainingAmount: "Ramaining amount",
      modeOfFees: "Mode of fees",
      receivedFrom: "Received from",
      courseDueBalance: "Course due balance",
      totalDue: "Total due",
      addAdditionalFees: "Add Additional Fees",
      reason: "Reason",
      amount: "Amount",
      cancel: "Cancel",
      courseFees: "Course fees",
      receivedFeesHistory: "Received fees history",
      receivedAdditionalFeesHistory: "Received additional fees history",
      discountsHistory: "Discounts history",
      save: "Save",
      submissionDate: "Submission date",
      paidAmount: "Paid amount",
      paymentMode: "Payment mode",
      cash: "Cash",
      upi: "UPI",
      internetBanking: "Intarnet Banking",
      cheque: "Cheque",
      bonus: "Bonus",
      deduction: "Deduction",
      title: "Title",
      detail: "Detail",
      usedBy: "Used by",
      date: "Date",
      addNewExpense: "Add new expense",
      searchExpense: "Search expense.......",
      quantity: "Quantity",
      availableQuantity: "Available Quantity",
      addInstituteKit: "Add institute Kit",
      searchInstituteKit: "Search institute kit....",
      updateInstituteKit: "Update institute Kit",
      productName: "Product name",
      totalQuantitySold: "Total quantity sold",
      totalPrice: "Total price",
      totalDiscount: "Total discount",
      studentName: "Student Name",
      searchSalesByStudentName: "Search sales by student name......",
      sellItem: "Sell Item",
      selectInstituteKit: "Select Institute Kit",
      itemName: "Item name",
      amountPerPeice: "Amount per peice",
      itemTotalAmount: "Item Total Amount",
      itemDiscountAmount: "Item Discount Amount",
      remark: "Remark",
      totalQuantity: "Total quantity",
      totalAmount: "Total Amount",
      submit: "Submit",
      update: "Update",
      searchHere: "Search Here",
      home: "Home",
      setting: "Settings",
      updateProfile: "Update Profile",
      changePassword: "Change Password",
      currentPassword: "Current Password",
      newPassword: "New Password",
      confirmPassword: "Confirm Passwoed",
      confirmYourNewPassword: "Confirm your new password",
      enterYourNewPassword: "Enter your new password",
      enterYourCurrentPassword: "Enter your current password",
      enterYourName: "Enter your name",
      enterYourMobileNo: "Enter your mobile  no",
      enterYourEmail: "Enter your email",
      enterYourQualification: "Enter your qualification",
      enterYourPinCode: "Enter your pin code",
      enterYourAddress: "Enter your address",
      updateExpenseDetails: "Update expense details",
      addExpense: "Add Expense",
      expenseTitle: "Expense title",
      expenseDetail: "Expense detail",
      selectDetail: "Select detail",
      others: "Others",
      selectStaff: "Select staff",
      newInstituteKit: "New Kit",
      schoolKitIsDeletedSuccessfully: "School kit is deleted successfully",
      susuccess: "Success",
      somethingWentWrong: "Something went wrong",
      error: "error",
      schoolKitIsUpdatedSuccessfully: "School kit is updated successfully",
      schoolKitIsAddedSuccessfully: "School kit is added successfully",
      expenseIsUpdatedSuccessfully: "Expense is updated successfully",
      invalidData: "invalid data",
      expenseIsAddedSuccessfully: "Expense is added successfully",
      profileIsUpdatedSuccessfully: "Profile is updated successfully",
      pleaseAddInstituteFirstToAddExpenses:
        "Please add institute first to add expenses",
      pleaseAddInstituteFirstToAddFees:
        "Please add institute first to add fees",
      studentConfirmation: "Student confirmation",
      doYouWantToAddStudent: "Do you want to add student ?",
      batchName: "Batch name",
      feesConfirmation: "Fees confirmation",
      doYouWantToSubmitFees: "Do you want to submit fees?",
      totalPaid: "Total paid",
      remainingBalance: "Remaining balance",
      staffConfirmation: "Staff confirmation",
      doYouWantToAddStaff: "Do you want to  add staff",
      batchConfirmation: "Batch confirmation",
      doYouWantToAddBatch: "Do you want to add batch? ",
      feeReceiptGeneratedSuccessfully: "Fee receipt generated successfully",
      description: "Description",
      balancePaid: "Balance paid",
      total: "Total",
      paidBy: "Paid by",
      FeeReceipt: "FEE RECEIPT",
      receiptNo: "Receipt No",
      billTo: "Bill To",
      instituteSignature: "Institute Signature",
      generatePDFToPrint: "Generate PDF to print",
      print: "Print",
      staffReceipt: "Staff Receipt",
      general: "General",
      obc: "OBC",
      sc: "SC",
      st: "ST",
      admissionTo: "Admission to",
      studentReceipt: "Student Receipt",
      administration: "Administration",
      instituteStore: "Institute Store",
      bulkUpload: "Bulk Upload",
      backup: "Back Up",
      clickToGenerateEncryptedBackup: "Click to generate encrypted backup",
      thisWillIncludeAllTheDataOfYourInstituteUpToThisTimeItWillBeSentToYourRegisteredEmail:
        "This will include all the data of your institute up to this time. It will be sent to your registered email.",
      ifYouNeedToRestoreTheDataAtAnyPointSimplySendAnEmailTo:
        "If you need to restore the data at any point, simply send an email to",
      toEnableTheRestorationOption: "to enable the restoration option.",
      uploading: "Uploading...",
      clickOrDragFileToThisAreaToUpload:
        "Click or drag file to this area to upload",
      restoreData: "Restore data",
      noteThisWillDeleteAllYourCurrentDataAndRestoreYourUploadedFileBackup:
        "Note: This will delete all your current data and restore your uploaded file backup.",
      supportForASingleUploadMaximumFileSize:
        "Support for a single upload maximum file size",
      upload: "Upload",
      selectDate:"Select date",
      present:"Present",
      absent:"Absent",
      leave:"Leave",
      studentAttendance:"Student Attendance",
      staffAttendence:"Staff Attendance",
    },
  },
  hi: {
    translation: {
      // Your Hindi translations go here
      welcome: "स्वागत है",
      staff: "कर्मचारी",
      dashboard: "डैशबोर्ड",
      institute: "संस्था",
      instituteName: "संस्थान का नाम",
      batchName: "बैच का नाम",
      batch: "बैच",
      student: "विद्यार्थी",
      fees: "शुल्क",
      salary: "वेतन",
      expenses: "खर्च",
      instituteKit: "संस्था संग्रहालय",
      logout: "लॉग आउट",
      studentSummary: "छात्र सारांश",
      staffSummary: "स्टाफ सारांश",
      newAdmission: "नया प्रवेश",
      newStaff: "नया कर्मचारी",
      paySalary: "वेतन का भुगतान",
      newBatch: "नया बैच",
      newExpenses: "नए खर्चे",
      newInstituteKit: "संस्था संग्रहालय",
      sellInstituteKit: "स्कूल किट बेचें",
      kitSales: "स्कूल किट बेचें",
      fromDate: "तिथि से",
      toDate: "तारीख तक",
      feeCollection: "शुल्क जमा",
      pendingFees: "लंबित शुल्क",
      discountFees: "छूट शुल्क",
      paidSalary: "वेतन दिया",
      salaryDue: "वेतन बकाया",
      studentKitSales: "छात्र किट बिक्री",
      feeDetails: "शुल्क विवरण",
      instituteMonthlySummary: "संस्थान का मासिक सारांश *",
      sales: "बिक्री",
      salaryDetails: "वेतन विवरण",
      batchSummary: "बैच सारांश",
      submitFee: "शुल्क जमा करें",
      male: "पुरुष",
      female: "महिला",
      studentsOfEachBatch: "प्रत्येक बैच के छात्र",
      totalStaffs: "कुल कर्मचारी",
      totalStudents: "कुल छात्र",
      instituteRegistrationNo: "संस्थान पंजीकरण संख्या",
      instituteMobile: "संस्थान मोबाइल",
      instituteEmail: "संस्थान ईमेल",
      city: "शहर ",
      pinCode: "पिन कोड",
      state: "राज्य ",
      country: "देश ",
      address: "पता ",
      instituteLogo: "संस्थान का लोगो",
      authorisedSignature: "अधिकृत हस्ताक्षर",
      note: "नोट*: संस्थान का विवरण, लोगो और हस्ताक्षर का उपयोग छात्र आईडी कार्ड, फीस रसीद, वेतन रसीद आदि में किया जाएगा।",
      name: "नाम ",
      batchTeacher: "बैच शिक्षक",
      feeType: "शुल्क प्रकार ",
      defaultFee: "डिफ़ॉल्ट शुल्क ",
      startDate: "आरंभ तिथि",
      endDate: "अंतिम तिथि",
      mobile: "मोबाइल नंबर",
      email: "ईमेल",
      adharNumber: "आधार नंबर",
      qualification: "योग्यता",
      joiningDate: "सेवारंभ तारीख *",
      salaryAmount: "वेतन राशि",
      role: "भूमिका",
      admin: "व्यवस्थापक",
      teacher: "अध्यापक",
      principal: "प्रधानाचार्य",
      other: "अन्य",
      salaryType: "वेतन प्रकार",
      monthly: "महीने के",
      courseWise: "कोर्स के अनुसार",
      oneTime: "एक बार",
      gender: "लिंग",
      attachPhoto: "फोटो संलग्न करें",
      addStaff: "कर्मचारी जोड़ें",
      updateStaff: "अपडेट कर्मचारी",
      updateInstituteDetails: "संस्थान विवरण अपडेट करें",
      addYourInstitute: "अपना संस्थान जोड़ें",
      updateInstitute: "अपडेट संस्थान",
      profile: "रूपरेखा",
      staffNo: "कर्मचारी नं.",
      action: "कार्रवाई",
      to: "तिथि तक",
      from: "तिथि से",
      updateBatchDetails: "बैच अपडेट करें",
      addBatch: "बैच जोड़ें",
      batchNumber: "बैच संख्या",
      searchStaffs: "कर्मचारी खोजें....",
      searchBatch: "बैच खोजें....",
      mother: "माता",
      father: "पिता",
      rollNo: "रोल नंबर",
      dob: "जन्म तिथि",
      mobileNo: "मोबाइल",
      newStudent: "नया छात्र",
      studentList: "छात्र सूची",
      searchStudents: "छात्र खोजें.....",
      selectBatch: "बैच चयन करें",
      reset: "रीसेट",
      updateStudentDetails: "छात्र विवरण अपडेट करें",
      addStudent: "छात्र जोड़ें",
      caste: "जाति",
      admissionDate: "प्रवेश तिथि",
      paid: "चुकाया गया",
      discount: "छूट",
      remaining: "शेष",
      paymentDate: "भुगतान तिथि",
      receipt: "रसीद",
      submitFees: "शुल्क जमा करें",
      receivedFeesList: "प्राप्त फीस सूची",
      searchStudentByNameRollNo: "छात्र को नाम, रोल नंबर से खोजें",
      searchStaffByNameStaffId: "नाम, कर्मचारी आईडी के आधार पर कर्मचारी खोजें",
      addFees: "शुल्क जोड़ें",
      updateFeesDetails: "फीस विवरण अपडेट करें",
      discountAmount: "छूट रकम",
      payAmount: "भुगतान राशि",
      remainingAmount: "शेष राशि",
      modeOfFees: "शुल्क का प्रकार",
      receivedFrom: "से प्राप्त",
      courseDueBalance: " कोर्स बकाया कोर्स",
      totalDue: "बकाया राशि",
      addAdditionalFees: "अतिरिक्त शुल्क जोड़ें",
      reason: "कारण",
      amount: "राशि",
      courseFees: "कोर्स कोर्स",
      receivedFeesHistory: "प्राप्त शुल्क विवरण ",
      receivedAdditionalFeesHistory: "अतिरिक्त प्राप्त हुआ शुल्क विवरण",
      discountsHistory: "छूट का विवरण",
      cancel: "रद्द करना",
      save: "संचय करना",
      submissionDate: "जमा करने की तिथि",
      paidAmount: "चुकाई गई राशि",
      paymentMode: "भुगतान का तरीका",
      cash: "नकद",
      upi: "एकीकृत भुगतान इंटरफ़ेस",
      internetBanking: "इंटरनेट बैंकिंग",
      cheque: "चेक",
      bonus: "बोनस",
      deduction: "कटौती",
      title: "शीर्षक",
      detail: "विवरण",
      usedBy: "उपयोग कर्ता",
      date: "तिथि",
      addNewExpense: "नया खर्च जोड़ें",
      searchExpense: "ख़र्च खोजे......",
      quantity: "मात्रा",
      availableQuantity: "उपलब्ध मात्रा",
      addInstituteKit: "संस्था संग्रहालय",
      searchInstituteKit: "संस्था संग्रहालय खोजें....",
      updateInstituteKit: "संस्थान किट अपडेट करे",
      productName: "सामान",
      totalQuantitySold: "बेंचा गया सामान",
      totalPrice: "कुल कीमत",
      totalDiscount: "कुल छूट",
      studentName: "छात्र नाम",
      searchSalesByStudentName: "छात्र के नाम से बिक्री खोजें......",
      sellItem: "सामान बेचो",
      selectInstituteKit: "संस्थान किट चुने",
      itemName: "सामान",
      amountPerPeice: "मात्रा प्रति पीस",
      itemTotalAmount: "कुल राशि",
      itemDiscountAmount: "कुल छूट",
      remark: "रिमार्क",
      totalQuantity: "कुल मात्रा",
      totalAmount: "कुल राशि",
      submit: "जमा करे",
      update: "अपडेट ",
      searchHere: "यहाँ खोजे",
      home: "होम ",
      setting: "सेटिंग",
      updateProfile: "अपडेट प्रोफ़ाइल ",
      changePassword: "पासवर्ड बदलें",
      currentPassword: "वर्तमान पासवर्ड",
      newPassword: "नया पासवर्ड",
      confirmPassword: "पासवोड की पुष्टि करें",
      confirmYourNewPassword: "अपने नए पासवर्ड की पुष्टि करें",
      enterYourNewPassword: "अपना नया पासवर्ड दर्ज करें",
      enterYourCurrentPassword: "वर्तमान पासवर्ड दर्ज करें",
      enterYourName: "अपना नाम दर्ज करें",
      enterYourMobileNo: "अपना मोबाइल नंबर दर्ज करें",
      enterYourEmail: "अपना ईमेल दर्ज करें",
      enterYourQualification: "अपनी योग्यता दर्ज करें",
      enterYourPinCode: "अपना पिन कोड दर्ज करें",
      enterYourAddress: "अपना पता दर्ज करें",
      updateExpenseDetails: "ख़र्च विवरण अपडेट करें",
      addExpense: "ख़र्च जोड़ें",
      expenseTitle: "ख़र्च शीर्षक",
      expenseDetail: "ख़र्च विवरण",
      selectDetail: "विवरण चुनें",
      others: "अन्य",
      selectStaff: "कर्मचारी का चयन करें",
      schoolKitIsDeletedSuccessfully: "स्कूल किट सफलतापूर्वक हटा दी गई है",
      susuccess: "संपन्न",
      somethingWentWrong: "कुछ गलत हो गया",
      error: "गलती",
      schoolKitIsUpdatedSuccessfully: "स्कूल किट सफलतापूर्वक अपडेट हो गई है",
      schoolKitIsAddedSuccessfully: "स्कूल किट सफलतापूर्वक जोड़ दी गई है",
      expenseIsUpdatedSuccessfully: "ख़र्च सफलतापूर्वक अपडेट किया गया है",
      invalidData: "अमान्य डेटा",
      expenseIsAddedSuccessfully: "ख़र्च सफलतापूर्वक जोड़ा गया",
      profileIsUpdatedSuccessfully: "प्रोफ़ाइल सफलतापूर्वक अपडेट हो गई है",
      pleaseAddInstituteFirstToAddExpenses:
        "कृपया खर्च जोड़ने के लिए पहले संस्थान जोड़ें",
      pleaseAddInstituteFirstToAddFees:
        "कृपया फीस जोड़ने के लिए पहले संस्थान जोड़ें",
      studentConfirmation: "छात्र की पुष्टि करें",
      doYouWantToAddStudent: "क्या आप नया छात्र जोड़ना चाहते हैं ?",
      feesConfirmation: "फीस की पुष्टि करें",
      doYouWantToSubmitFees: "क्या आप फीस जमा करना चाहते हैं?",
      totalPaid: "कुल भुगतान",
      remainingBalance: "शेष बकाया",
      staffConfirmation: "स्टाफ की पुष्टि करें",
      doYouWantToAddStaff: "क्या आप नया स्टाफ जोड़ना चाहते हैं ?",
      batchConfirmation: "बैच की पुष्टि करें",
      doYouWantToAddBatch: "क्या आप नया बैच जोड़ना चाहते हैं?",
      feeReceiptGeneratedSuccessfully: "शुल्क रसीद सफलतापूर्वक उत्पन्न हुई",
      description: "विवरण",
      balancePaid: "भुगतान की गयी राशि",
      total: "कुल",
      paidBy: "शुल्क जमाकार्ता",
      FeeReceipt: "शुल्क रसीद",
      receiptNo: "रसीद संख्या",
      billTo: "रसीद प्राप्तकर्ता",
      instituteSignature: "संस्थान के हस्ताक्षर",
      generatePDFToPrint: "प्रिंट करने के लिए पीडीएफ जेनरेट करें",
      print: "प्रिंट",
      staffReceipt: "स्टाफ रसीद",
      studentReceipt: "Student Receipt",
      administration: "व्यवस्थापक",
      instituteStore: "संस्थान भंडार",
      bulkUpload: "बल्क अपलोड",
      backup: "बैकअप",
      clickToGenerateEncryptedBackup:
        "एन्क्रिप्टेड बैकअप जनरेट करने के लिए क्लिक करें",
      thisWillIncludeAllTheDataOfYourInstituteUpToThisTimeItWillBeSentToYourRegisteredEmail:
        "इसमें आपके संस्थान का अब तक का सारा डेटा शामिल होगा. यह आपके पंजीकृत ईमेल पर भेजा जाएगा।",
      ifYouNeedToRestoreTheDataAtAnyPointSimplySendAnEmailTo:
        "यदि आपको किसी भी समय डेटा को पुनर्स्थापित करने की आवश्यकता है, तो बस एक ईमेल भेजें",
      toEnableTheRestorationOption: "पुनर्स्थापना विकल्प को सक्षम करने के लिए।",
      uploading: "अपलोड हो रहा है...",
      clickOrDragFileToThisAreaToUpload:
        "अपलोड करने के लिए फ़ाइल को इस क्षेत्र में क्लिक करें या खींचें",
      restoreData: "डेटा पुनर्स्थापित करें",
      noteThisWillDeleteAllYourCurrentDataAndRestoreYourUploadedFileBackup:
        "ध्यान दें : यह आपके सभी मौजूदा डेटा को हटा देगा और आपके अपलोड किए गए फ़ाइल बैकअप को पुनर्स्थापित कर देगा।",
      upload: "अपलोड करें",
      selectDate:"तारीख़ चुनें",
      present:"उपस्थित",
      absent:"अनुपस्थित",
      leave:"छुट्टी",
      studentAttendance:"छात्र उपस्थिति",
      staffAttendance:"स्टाफ उपस्थिति",

    },
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: "en", // Fallback language
  interpolation: {
    escapeValue: false, // React already safes from XSS
  },
});

export default i18n;
